<template>
  <div>
    <UtilLoader :loading="load.loading">
      <template #loading>
        <img src="@/assets/img/haulage-loader.gif" class="opacity-25 w-64 m-auto" />
      </template>
      <template #default>
        <table class="w-full">
          <tbody>
            <tr>
              <th>Load Reference</th>
              <td>{{ loadData.loadId }}</td>
            </tr>
            <tr>
              <th>Last Updated</th>
              <td>{{ loadData.updatedAt }}</td>
            </tr>
            <tr>
              <th>Collection</th>
              <td>{{ loadData.from }}</td>
            </tr>
            <tr>
              <th>Delivery</th>
              <td>{{ loadData.to }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </UtilLoader>
  </div>
</template>

<script>
import UtilLoader from '@/components/utilities/UtilLoader'

export default {
  name: 'HaulageLoad',
  components: {
    UtilLoader
  },
  data() {
    return {
      haulageApiUrl: process.env.VUE_APP_HAULAGE_API_BASE_URL,

      load: this.$call()
    }
  },
  created() {
    this.getLoad()
  },
  computed: {
    loadData() {
      return this.load.response.data
    }
  },
  watch: {},
  methods: {
    async getLoad() {
      this.load.method = 'GET'
      this.load.url = `${this.haulageApiUrl}/v1/loads/${this.$route.params.id}`
      await this.$api(this.load)
    }
  }
}
</script>

<style lang="scss" scoped></style>
