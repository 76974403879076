<template>
  <div>
    <slot v-if="loading" name="loading">Loading...</slot>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'UtilLoader',
  props: {
    loading: {}
  }
}
</script>

<style lang="scss" scoped></style>
